











































import { Component, Prop, Vue } from "vue-property-decorator";
import FormView from "@/components/FormView.vue";
import WorkHoursView from "@/components/WorkHours/WorkHoursView.vue";
import type { IDayWorkHour } from "@/interfaces/IDayWorkHour";
import _ from "lodash";

@Component({
  components: { WorkHoursView, FormView },
})
export default class BranchView extends Vue {
  public name = "BranchView";

  @Prop()
  public data!: any;

  @Prop()
  public workHours!: Array<IDayWorkHour>;

  public closeView(): void {
    this.$emit("close");
  }

  public get postCodeLocation(): string {
    return this.data && `${this.data.postalCode}, ${this.data.location}`;
  }

  public get fullName(): string {
    return this.data && `${this.data.abbreviation}`;
  }

  public get streetAndNumber(): string {
    return this.data && `${this.data.streetAndNumber}`;
  }

  public get priceList(): string {
    if (!this.data.priceList) {
      return "";
    }
    return this.data && `${this.data.priceList.name}`;
  }

  public isNotEmpty(val: any): boolean {
    return !_.isEmpty(val);
  }
}
