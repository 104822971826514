




import { Component, Prop, Vue } from "vue-property-decorator";
import Panel from "@/components/Panel.vue";
import WorkHours from "./WorkHours.vue";

@Component({
  components: {
    WorkHours,
    Panel,
  },
})
export default class WorkHoursView extends Vue {
  public name = "WorkHoursView";

  @Prop()
  public data!: any;
}
