





































































import { Component, Prop, Watch } from "vue-property-decorator";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import { namespace } from "vuex-class";
import { Validations } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import Validation from "@/components/Validation.vue";
import type { IBranch } from "@/interfaces/IBranch";
import type { IDayWorkHour } from "@/interfaces/IDayWorkHour";
import WorkHoursPanel from "@/components/WorkHours/WorkHoursPanel.vue";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import { IBasicNamedDTO } from "@/interfaces/IBasicNamedDTO";
import CompanyMixin from "@/mixins/CompanyMixin";
import { mixins } from "vue-class-component";

const BranchModule = namespace("branch");
const PriceListModule = namespace("price-list");

@Component({
  components: {
    FscSimpleCard,
    WorkHoursPanel,
    Validation,
    SaveButton,
    AbortButton,
  },
})
export default class BranchEdit extends mixins(CompanyMixin) {
  public name = "BranchEdit";

  @Prop({ type: Boolean, default: () => true })
  public isEdit!: any;

  @Prop({ type: String })
  public headerTitle!: string;

  @Prop()
  public data!: IBranch;

  @Prop({ type: Boolean, required: false, default: () => false })
  public putLoading!: boolean;

  public abbreviation = "";
  public postalCode: string | undefined = "";
  public location: string | undefined = "";
  public streetAndNumber = "";
  public priceList: IBasicNamedDTO | null = null;

  public daysOfWeek!: Array<IDayWorkHour>;

  @BranchModule.Action("workHours")
  public workHoursAction: any;

  @BranchModule.Getter("getWorkHours")
  public workHours: any;

  @PriceListModule.Action("findAll")
  protected priceListFindAll: any;

  @PriceListModule.Getter("getDataList")
  protected priceLists!: Array<any>;

  public mounted(): void {
    this.priceListFindAll({ resource: "price-lists" });
  }

  public onAbort(): void {
    this.$emit("abort");
  }

  public onSubmit(): void {
    if (this.hasNotPermissionWrite) return;
    this.$v.$touch();

    if (this.$v.$invalid) return;

    const daysOfWeek = this.daysOfWeek?.filter((x: IDayWorkHour) => x.startTime && x.endTime);

    const branch: IBranch = {
      abbreviation: this.abbreviation,
      postalCode: this.postalCode,
      location: this.location,
      streetAndNumber: this.streetAndNumber,
      priceList: this.priceList,
    };

    if (daysOfWeek) {
      Object.assign(branch, { dayWorkHours: [...daysOfWeek] });
    }

    this.$emit("on-submit", branch);
  }

  @Validations()
  public validations(): Record<string, any> {
    return {
      abbreviation: { required },
      postalCode: { required },
      location: { required },
    };
  }

  @Watch("data", { deep: true, immediate: true })
  public initData(data: IBranch): void {
    if (data) {
      this.abbreviation = data.abbreviation;
      this.postalCode = data.postalCode;
      this.location = data.location;
      this.streetAndNumber = data.streetAndNumber;
      this.priceList = data.priceList;
    }
  }

  public builtDaysOfWeek(daysOfWeek: Array<IDayWorkHour>): void {
    this.daysOfWeek = daysOfWeek;
  }
}
